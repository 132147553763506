/* src/index.css */

/* Import Tailwind base styles */
@import 'tailwindcss/base';

/* Import Tailwind component classes */
@import 'tailwindcss/components';

/* Custom styles for Blue Magic website */
@layer base {
  :root {
    --color-blue-magic-50: #ecf3ff;
    --color-blue-magic-100: #d9e6ff;
    --color-blue-magic-900: #002899;

    --color-neon-50: #fffee6;
    --color-neon-500: #f9e56a;
    --color-neon-300: #d3c345;

    --font-family-header: 'Austin Web', sans-serif;
    --font-family-body: 'Open Sans', sans-serif;
  }
}

/* Custom styles */
body {
  background-color: var(--color-blue-magic-50);
  font-family: var(--font-family-body);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-header);
}

a.social-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 120px;
  text-decoration: none;
  color: var(--color-blue-magic-900);
  background-color: white;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

a.social-button:hover {
  background-color: var(--color-blue-magic-100);
}

.fixed-icon {
  height: 50px !important;
  width: 50px !important;
}

.rainbow {
  font-size: 3rem;
  font-weight: bold;
  background: linear-gradient(90deg, #ff0000, #ffa500, #ffff00, #008000, #0000ff, #4b0082, #ee82ee);
  background-size: 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: rainbow 3s ease-in-out infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

header {
  background-color: var(--color-blue-magic-900);
  color: white;
  padding: 1rem 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

nav {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

nav a:hover {
  color: var(--color-neon-500);
}

ul {
  display: flex;
  gap: 1.5rem;
}

ul li a {
  padding: 0.5rem 1rem;
  background-color: var(--color-neon-500);
  color: var(--color-blue-magic-900);
  border-radius: 9999px;
  transition: background-color 0.3s ease;
}

ul li a:hover {
  background-color: var(--color-neon-300);
}

/* Import Tailwind utility classes */
@import 'tailwindcss/utilities';
